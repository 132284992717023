<template>
    <landing :breadCrumbs="breadCrumbs" :shopName="shopName">
        <v-container>
            <v-row
                id="description"
                justify="center"
                :class="smAndUp ? 'mb-15' : null"
            >
                <v-col cols="auto" align="center">
                    <heading
                        ><span class="red--text">平</span
                        >井蔵前橋通り店</heading
                    >
                </v-col>
            </v-row>

            <!-- 紹介文 -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">アクセス</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >JR総武線 平井駅徒歩3分!</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">住所</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >東京都江戸川区平井5丁目11-6サンムーン石原</span
                        >
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">営業時間</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >平日13:00～22:00/土日10:00～18:00<br />※時間外は応相談</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">定休日</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >月曜日・金曜日</span
                        >
                    </div>
                </v-col>
            </v-row>

            <!-- 代表イメージ -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img
                            position="top center"
                            height="360px"
                            :src="imageMv"
                        ></v-img>
                        <!-- <v-card-title class="pl-0"
                            >JR総武線 平井駅徒歩3分！</v-card-title
                        >
                        <v-card-subtitle class="pl-0">
                            東京都江戸川区平井5丁目11-6サンムーン石原
                        </v-card-subtitle> -->
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img
                            position="bottom center"
                            height="360px"
                            :src="shopImage"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col v-if="!smAndUp" cols="12" align="center">
                    <v-icon
                        small
                        color="red darken-2"
                        @click="$vuetify.goTo('#target')"
                        >fas fa-chevron-down</v-icon
                    >
                    駅からのご案内
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <GmapMap
                            :center="center"
                            :zoom="zoom"
                            map-type-id="terrain"
                            style="width: 100%; height: 360px"
                        >
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :clickable="true"
                                :draggable="true"
                                @click="center = m.position"
                            />
                        </GmapMap>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!4v1687331105548!6m8!1m7!1s5EC1CnS_mGUoaaqETN5I7Q!2m2!1d35.70669993287169!2d139.8400778899618!3f133.25929377116753!4f-2.8723260342095926!5f1.9587109090973311"
                            width="100%"
                            height="360px"
                            style="border:0;"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                        <v-card-text
                            >※Googleストリートビューの画像ではREVOISTの看板が表示されていない場合がありますが実際には店舗前に掲示されています。</v-card-text
                        >
                    </v-card>
                </v-col>
            </v-row>
            <!-- 店舗個別メッセージ -->
            <shop-message :message="this.message" :shopImage="trainerImage" />
            <!-- ナビゲーション -->
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <heading
                        ><span id="target" class="red--text">平井駅</span
                        >からお越しの方</heading
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation text-body-1">
                            <span class="red--text text-md-h6">1</span>.
                            改札を出て左側の北口（バスロータリー）に進んでください。
                        </div></v-card-title
                    >
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessA"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation text-body-1">
                            <span class="red--text text-md-h6">2</span>.
                            ドトール側へ左に曲がります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessB"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation text-body-1">
                            <span class="red--text text-md-h6">3</span>.
                            バスロータリーに沿って、吉野家の方へ真っすぐ進みます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessC"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation text-body-1">
                            <span class="red--text text-md-h6">4</span>.
                            蔵前橋通りに向かってファミリーマートも真っすぐ進みます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessD"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation text-body-1">
                            <span class="red--text text-md-h6">5</span>.
                            蔵前橋通り沿いの黄色い看板のラーメン屋さんを左に曲がります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessE"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation text-body-1">
                            <span class="red--text text-md-h6">6</span>.
                            蔵前橋通り沿いを真っすぐ歩きます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessF"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation text-body-1">
                            <span class="red--text text-md-h6">7</span>.
                            オレンジ色レンガ調の建物の奥側（2つ目）がREVOISTの建物です。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessG"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation text-body-1">
                            <span class="red--text text-md-h6">8</span>.
                            こちらがREVOIST平井蔵前橋通り店です。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessH"></v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </landing>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import ShopMessage from '@/components/landing/ShopMessage.vue'
import Landing from '@/views/Landing.vue'

export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
        document.querySelector('meta[name="description"]').setAttribute(
            'content',
            `パーソナルジム REVOISTの9号店です！
平井駅北口から徒歩3分の蔵前橋通り沿いにある駅近のジムです！
JR初出店で、JR沿線の方でも、レボイストのパーソナルトレーニングに通うことができます！
現在、平井にお住まいのお客様の新規体験を募集中です！
真面目で爽やかなパーソナルトレーナーが、あなたの身体の悩みに対して、真剣に向き合い、コミュニケーションも大切にしながら、楽しくレッスンを提供します！`
        )
    },
    components: {
        Heading,
        FooterContents,
        Landing,
        ShopMessage,
    },
    data() {
        return {
            shopName: '平井蔵前橋通り店',
            center: {
                lat: 35.70680544369998,
                lng: 139.84025215591302,
            },
            zoom: 18,
            markers: [
                {
                    position: {
                        lat: 35.70680544369998,
                        lng: 139.84025215591302,
                    },
                    title: '平井蔵前橋通り店',
                },
            ],
            message: `パーソナルジム REVOISTの9号店です！<br />
平井駅北口から徒歩3分の蔵前橋通り沿いにある駅近のジムです！<br />
JR初出店で、JR沿線の方でも、レボイストのパーソナルトレーニングに通うことができます！<br />
現在、平井にお住まいのお客様の新規体験を募集中です！<br />
真面目で爽やかなパーソナルトレーナーが、あなたの身体の悩みに対して、真剣に向き合い、コミュニケーションも大切にしながら、楽しくレッスンを提供します！<br /><br />
カラダの悩みを一緒に共有し、トレーニングを楽しく丁寧にお伝えいたします！
トレーニングを通して、心と身体も健康にしていきましょう！<br />
平井蔵前橋通り店トレーナー：藪下翔
`,
        }
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: '店舗一覧・アクセス',
                    disabled: false,
                    href: 'gyms',
                },
                {
                    text: '平井蔵前橋通り店',
                    disabled: true,
                    href: 'access-7',
                },
            ]
        },

        imageMv() {
            return 'static/pc_access_9/Access9_8_2.jpg'
        },
        accessA() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_9/Access9_1.jpg'
                : 'static/pc_access_9/Access9_1.jpg'
        },
        accessB() {
            return 'static/pc_access_9/Access9_2.jpg'
        },
        accessC() {
            return 'static/pc_access_9/Access9_3.jpg'
        },
        accessD() {
            return 'static/pc_access_9/Access9_4.jpg'
        },
        accessE() {
            return 'static/pc_access_9/Access9_5.jpg'
        },
        accessF() {
            return 'static/pc_access_9/Access9_6.jpg'
        },
        accessG() {
            return 'static/pc_access_9/Access9_7.jpg'
        },
        accessH() {
            return 'static/pc_access_9/Access9_8_2.jpg'
        },
        accessI() {
            return 'static/pc_access_9/Access9_8_2.jpg'
        },
        shopImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/HiraiKurameDori.jpg'
                : 'static/landing/HiraiKurameDori.jpg'
        },
        trainerImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/trainer/平井蔵前橋通り店.jpg'
                : 'static/landing/trainer/平井蔵前橋通り店.jpg'
        },
    },
}
</script>
<style lang="scss">
.navigation {
    min-height: 3em;
}
</style>
